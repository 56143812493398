import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BigNumber, ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import {
  Coin,
  fund,
  getAmountFundedByAddress,
  getCoinDepositContract,
  getIsContractOwner,
  getIsAllownceApproved,
  getStoredBalance,
  withdraw,
  approve,
} from "../../App.utils";
import { useAppContext } from "../../context/app.useContext";

const BodyComponent = () => {
  const { state } = useAppContext();

  const [selectedCoin, setSelectedCoin] = useState<Coin>({
    name: "",
    symbol: "",
    decimals: 18,
    depositContractAddress: "",
    depositContract: null,
  });
  const [selectedCoinBalance, setSelectedCoinBalance] = useState(
    BigNumber.from(0)
  );
  const [selectedAmountToTransfer, setSelectedAmountToTransfer] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [selectedAmountToTransferStr, setSelectedAmountToTransferStr] = useState(
    ''
  );
  const [isCoinDropDownShown, setIsCoinDropDownShown] = useState(false);

  const [isHavingSufficientFunds, setIsHavingSufficientFunds] = useState(true);
  const [isApprovalNeeded, setIsApprovalNeeded] = useState(false);
  const [amountFundedByUser, setAmountFundedByUser] = useState(
    BigNumber.from(0)
    );

  const coinDropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleDropdownClickOutside = (event: MouseEvent) => {
      if (
        coinDropdownRef.current &&
        event.target &&
        !coinDropdownRef.current.contains(event.target as Node)
      ) {
        setIsCoinDropDownShown(false);
      }
    };
    document.addEventListener("mousedown", handleDropdownClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleDropdownClickOutside);
    };
  }, [coinDropdownRef]);

  const [isLoading, setIsLoading] = useState(false);
  const [isContractOwner, setIsContractOwner] = useState<boolean | null>(null);
  const coinName = selectedCoin.name;
  useEffect(() => {
    const getIsOwner = async () => {
      const isOwner = await getIsContractOwner(
        selectedCoin,
        state.web3Provider
      );
      setIsContractOwner(isOwner);
    };

    getIsOwner();
  }, [coinName, state.userAddress]);

  const chainInfoListMap = state.chainInfoListMap;
  const coinList =
    state.chainInfo && (chainInfoListMap.get(state.chainInfo.id) ?? null);
  const coinNameList = (coinList?.map((coin) => coin.name) ?? []).toString();
  useEffect(() => {
    if (coinList !== null) {
      setSelectedCoin(coinList[0]);
    }
  }, [coinNameList, state.userAddress]);

  const selectedCoinString = selectedCoin.name;
  useEffect(() => {
    const getBalance = async () => {
      const useCurrentBalance = await getCoinDepositContract(
        state.userAddress,
        state.web3Provider,
        selectedCoin
      );
      setSelectedCoinBalance(useCurrentBalance ?? BigNumber.from(0));
    };

    getBalance();
  }, [selectedCoinString, state.userAddress]);

  const [contractStoredBalance, setContractStoredBalance] = useState(
    BigNumber.from(0)
  );
  useEffect(() => {
    const getBalance = async () => {
      const balance = await getStoredBalance(selectedCoin);
      setContractStoredBalance(balance ?? BigNumber.from(0));
    };

    if (isContractOwner) {
      getBalance();
    }
  }, [isContractOwner, coinName]);

  const amountToTransfer = selectedAmountToTransfer?.toString() ?? null;
  useEffect(() => {
    if (selectedAmountToTransfer === null) {
      return
    }

    const getCanBeTransfered = async () => {
      const { areFundsEnough, isTransferAllowed } = await getIsAllownceApproved(
        selectedAmountToTransfer,
        state.userAddress,
        selectedCoin,
        state.web3Provider
      );

      setIsHavingSufficientFunds(areFundsEnough);
      setIsApprovalNeeded(!isTransferAllowed);
    };

    getCanBeTransfered();
  }, [amountToTransfer]);

  useEffect(() => {
    const amountFundedByAddress = async () => {
      const fundedAmount = await getAmountFundedByAddress(
        selectedCoin,
        state.userAddress
      );

      setAmountFundedByUser(fundedAmount ?? BigNumber.from(0));
    };

    amountFundedByAddress();
  }, [isLoading, selectedCoinString]);

  const onApproveHandle = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoading(true);

    await approve(selectedCoin, selectedAmountToTransfer);

    setIsLoading(false);
  };

  const onFundMeHandle = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (selectedAmountToTransfer === null) {
      return
    }

    setIsLoading(true);

    await fund(selectedCoin, selectedAmountToTransfer);

    setIsLoading(false);
  };

  const onWithdrawHandle = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoading(true);

    await withdraw(selectedCoin);

    setIsLoading(false);
  };

  return (
    <div className="mx-auto flex flex-col">
      <div className="relative">
        <div className="mt-10 bg-white">
          <div className="flex gap-6 justify-between min-h-[1rem] px-6 pt-6 pb-1">
            <div className="flex">
              <input
                placeholder="0"
                value={selectedAmountToTransferStr}
                className="max-w-[15rem] p-2 my-auto font-medium text-2xl outline-none"
                onChange={(event) => {
                  setSelectedAmountToTransfer(
                    ethers.utils.parseUnits(
                      event.target.value.length !== 0 &&
                        !isNaN(Number(event.target.value))
                        ? event.target.value
                        : "0",
                      selectedCoin.decimals
                    )
                  );
                  setSelectedAmountToTransferStr(event.target.value)
                }}
              />
            </div>
            <div className="flex flex-col">
              <div ref={coinDropdownRef} className="relative py-auto">
                <button
                  onClick={() => {
                    setIsCoinDropDownShown(!isCoinDropDownShown);
                  }}
                  className="ml-auto py-2 px-4 flex gap-2 bg-blue-200 rounded-full shadow-md hover:bg-slate-500 transition-colors"
                >
                  <span>{selectedCoin.name}</span>
                  <FontAwesomeIcon className="my-auto" icon={faChevronDown} />
                </button>
                {isCoinDropDownShown &&
                  state.chainInfo &&
                  chainInfoListMap.get(state.chainInfo.id) && (
                    <div className="absolute top-8 z-20 w-full mt-3 p-2 bg-blue-200 text-black">
                      <ul>
                        {coinList &&
                          coinList.map((coin) => (
                            <li key={coin.name}>
                              <button
                                className="w-full text-start"
                                onClick={() => {
                                  setSelectedCoin(coin);
                                  setIsCoinDropDownShown(false);
                                }}
                              >
                                {coin.name}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="flex ml-auto px-6 pb-7 text-end">
            <span className="ml-auto px-2 text-end">
              {`Balance: ${ethers.utils.formatUnits(
                selectedCoinBalance,
                selectedCoin.decimals
              )}`}
            </span>
            <button
              className="text-red-600 font-bold"
              onClick={() => {
                setSelectedAmountToTransfer(selectedCoinBalance)
                setSelectedAmountToTransferStr(ethers.utils.formatUnits(
                  selectedCoinBalance,
                  selectedCoin.decimals
                ))
              }}
            >
              MAX
            </button>
          </div>
        </div>
        <div className="absolute z-10 -bottom-5 left-0 w-full flex">
          {isContractOwner ? (
            <div className="mx-auto flex gap-4">
              <button
                className={`mx-auto py-2 px-4 rounded-full text-white ${
                  !contractStoredBalance.isZero()
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-gray-500 hover:cursor-not-allowed"
                }`}
                onClick={onWithdrawHandle}
              >
                Withdraw
              </button>
              <button
                className={`mx-auto py-2 px-4 rounded-full text-white ${
                  isHavingSufficientFunds && !selectedAmountToTransfer?.isZero()
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-gray-500 hover:cursor-not-allowed"
                }`}
                onClick={
                  isApprovalNeeded && !selectedAmountToTransfer.isZero()
                    ? onApproveHandle
                    : onFundMeHandle
                }
              >
                {isHavingSufficientFunds ? (
                  <>
                    {isApprovalNeeded && !selectedAmountToTransfer.isZero()
                      ? "Approve"
                      : `Fund${
                        isContractOwner
                          ? ''
                          : ' Me'
                      }`}
                  </>
                ) : (
                  "Not Enough Funds"
                )}
              </button>
            </div>
          ) : (
            <button
              className={`mx-auto py-2 px-4 rounded-full text-white ${
                isHavingSufficientFunds && !selectedAmountToTransfer?.isZero()
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-gray-500 hover:cursor-not-allowed"
              }`}
                onClick={
                  isApprovalNeeded && !selectedAmountToTransfer.isZero()
                    ? onApproveHandle
                    : onFundMeHandle
                }
            >
              {isHavingSufficientFunds ? (
                <>
                  {isApprovalNeeded && !selectedAmountToTransfer?.isZero()
                    ? "Approve"
                    : "Fund Me"}
                </>
              ) : (
                "Not Enough Funds"
              )}
            </button>
          )}
        </div>
      </div>
      {isContractOwner ? (
        <div className="mt-8">
          <span className="px-2">{"Total available funds to withdraw"}</span>
          <span>{`${ethers.utils.formatUnits(
            contractStoredBalance,
            selectedCoin.decimals
          )} ${selectedCoin.symbol}`}</span>
        </div>
      ) : (
        <div className="mt-8">
          <span className="px-2">{"You funded me with"}</span>
          <span>{`${ethers.utils.formatUnits(
            amountFundedByUser,
            selectedCoin.decimals
          )} ${selectedCoin.symbol}`}</span>
        </div>
      )}
    </div>
  );
};

export default BodyComponent;
