import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./context/app.context";

//
import {
  DAppProvider,
  Config,
  Goerli,
  ZkSyncTestnet,
  Polygon,
  Mumbai,
  AvalancheTestnet,
  MoonbaseAlpha,
  OasisEmeraldTestnet,
} from "@usedapp/core";
import { OasisSapphireTestnet } from "./utils/customChains.utils";
import { ethers, getDefaultProvider } from "ethers";

const ETHEREUM_GOERLI_RPC = process.env.REACT_APP_ETHEREUM_GOERLI_RPC!;
//

declare global {
  interface Window {
    ethereum: any;
  }
}

//
const config: Config = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    // [Goerli.chainId]: getDefaultProvider("goerli"),
    [Goerli.chainId]: ETHEREUM_GOERLI_RPC,
  },
};
//

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
window.addEventListener("load", () => {
  root.render(
    <AppContextProvider>
      <React.StrictMode>
        <DAppProvider config={config}>
          <App />
        </DAppProvider>
      </React.StrictMode>
    </AppContextProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
