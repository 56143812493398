import BodyComponent from "./components/body/body.component";
import { useAppContext } from "./context/app.useContext";
import HeadComponent from "./components/head/head.component";
import { useEffect } from "react";
import { AppActionTypes } from "./context/app.types";
import {
  getChainInfoFromNetworkId,
  isConnectedToMetamask,
  setup,
} from "./App.utils";
import * as sapphire from "@oasisprotocol/sapphire-paratime";
import {
  Contract as ZkSyncContract,
  Web3Provider as ZkSyncWeb3Provider,
  Provider as ZkSyncProvider,
} from "zksync-web3";
import { ethers } from "ethers";

function App() {
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    if (isConnectedToMetamask()) {
      ConnectToMetamask();
    }
  }, []);

  const ConnectToMetamask = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const chainId = await window.ethereum.chainId;
      const address = accounts[0];

      const { provider, chainInfoListMap } = setup();

      window.ethereum.on("chainChanged", (chainId: any) => {
        window.location.reload();
        console.log("chainChanged");
      });

      window.ethereum.on("accountsChanged", async (accounts: Array<string>) => {
        const chainId = await window.ethereum.chainId;
        console.log("accountsChanged");

        let innerWeb3Provider: ethers.providers.Web3Provider | ZkSyncWeb3Provider | null = null
        if (chainId in sapphire.NETWORKS) {
          innerWeb3Provider = sapphire.wrap(provider)
        } else if (chainId === '0x118') {
          innerWeb3Provider = new ZkSyncWeb3Provider(window.ethereum)
        } else {
          innerWeb3Provider = provider
        }

        dispatch({
          action: AppActionTypes.SETUP,
          payload: {
            userAddress: accounts[0],
            chainInfo: getChainInfoFromNetworkId(chainId),
            // web3Provider:
            //   chainId in sapphire.NETWORKS ? sapphire.wrap(provider) : ((chainId === '0x118') ? new ZkSyncWeb3Provider(window.ethereum) : provider),
            web3Provider: innerWeb3Provider,
            chainInfoListMap: chainInfoListMap,
          },
        });

        window.location.reload();
      });

      dispatch({
        action: AppActionTypes.SETUP,
        payload: {
          userAddress: address,
          chainInfo: getChainInfoFromNetworkId(chainId),
          web3Provider:
            chainId in sapphire.NETWORKS ? sapphire.wrap(provider) : provider,
          chainInfoListMap: chainInfoListMap,
        },
      });

      return;
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col">
      <HeadComponent />
      <div className="flex-1 flex flex-col bg-pink-50">
        <div className="flex">
          <span className="mx-auto p-2">Fund Me</span>
        </div>
        {state.userAddress !== null && <BodyComponent />}
      </div>
    </div>
  );
}

export default App;
