import { createContext, Dispatch, useReducer } from "react";
import { BlockchainNetworkId, Coin } from "../App.utils";
import { appReducer } from "./app.reducer";
import { AppActionTypes, AppStoreState } from "./app.types";

const initialAppStoreState: AppStoreState = {
  userAddress: null,
  chainInfo: null,
  web3Provider: null,
  chainInfoListMap: new Map<BlockchainNetworkId, Coin[]>()
};

export const AppContext = createContext<{
  state: AppStoreState;
  dispatch: Dispatch<{
    action: AppActionTypes;
    payload: AppStoreState;
  }>;
} | null>(null);

type AppContextProviderProps = {
  children: JSX.Element;
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, initialAppStoreState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
