import { ethers } from "ethers";
import { BlockchainNetworkId, ChainInfo, Coin } from "../App.utils";
import {
  Contract as ZkSyncContract,
  Web3Provider as ZkSyncWeb3Provider,
  Provider as ZkSyncProvider,
} from "zksync-web3";

export type AppStoreState = {
  userAddress: string | null;
  chainInfo: ChainInfo | null;

  web3Provider: ethers.providers.Web3Provider | ZkSyncWeb3Provider | null;
  chainInfoListMap: Map<BlockchainNetworkId, Coin[]>;
};

export enum AppActionTypes {
  SETUP,
  SET_ADDRESS_AND_NETWORK,
  SET_NETWORK,
  SET_SELECTED_COIN,
}
