import { faChevronDown, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEthers } from "@usedapp/core";
import { useEffect, useRef, useState } from "react";
import {
  BlockchainNetworkId,
  chainInfoList,
  Coin,
  getChainInfoFromNetworkId,
  setup,
} from "../../App.utils";

import { AppActionTypes } from "../../context/app.types";
import { useAppContext } from "../../context/app.useContext";
import { saveIsConnected } from "./head.utils";

const HeadComponent = () => {
  const { state, dispatch } = useAppContext();
  // 
  // const { activateBrowserWallet, account } = useEthers()
  // 

  const [isChainDropDownShown, setIsChainDropDownShown] = useState(false);
  const [isUserDropDownShown, setIsUserDropDownShown] = useState(false);

  const chainDropdownRef = useRef<HTMLDivElement>(null);
  const userDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDropdownClickOutside = (event: MouseEvent) => {
      if (
        chainDropdownRef.current &&
        event.target &&
        !chainDropdownRef.current.contains(event.target as Node)
      ) {
        setIsChainDropDownShown(false);
      }
    };
    document.addEventListener("mousedown", handleDropdownClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleDropdownClickOutside);
    };
  }, [chainDropdownRef]);
  useEffect(() => {
    const handleDropdownClickOutside = (event: MouseEvent) => {
      if (
        userDropdownRef.current &&
        event.target &&
        !userDropdownRef.current.contains(event.target as Node)
      ) {
        setIsUserDropDownShown(false);
      }
    };
    document.addEventListener("mousedown", handleDropdownClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleDropdownClickOutside);
    };
  }, [userDropdownRef]);

  const requestAccountAddress = async () => {
    let address = state.userAddress;
    let chainId: string | null = null;

    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        chainId = await window.ethereum.chainId;
        address = accounts[0];
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      alert("Meta Mask not detected");
    }

    return {
      address,
      chainId,
    };
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      const { address, chainId } = await requestAccountAddress();

      saveIsConnected(true);

      if (state.web3Provider !== null) {
        const { provider, chainInfoListMap } = setup();
        dispatch({
          action: AppActionTypes.SETUP,
          payload: {
            userAddress: address,
            chainInfo: getChainInfoFromNetworkId(chainId),
            web3Provider: provider,
            chainInfoListMap: chainInfoListMap,
          },
        });
      } else {
        dispatch({
          action: AppActionTypes.SET_ADDRESS_AND_NETWORK,
          payload: {
            userAddress: address,
            chainInfo: getChainInfoFromNetworkId(chainId),
            web3Provider: null,
            chainInfoListMap: new Map<BlockchainNetworkId, Coin[]>(),
          },
        });
      }
    }
  };

  const disconnectWallet = () => {
    saveIsConnected(false);

    dispatch({
      action: AppActionTypes.SET_ADDRESS_AND_NETWORK,
      payload: {
        userAddress: null,
        chainInfo: null,
        web3Provider: null,
        chainInfoListMap: new Map<BlockchainNetworkId, Coin[]>(),
      },
    });
  };

  return (
    <header className="p-4 flex gap-2 justify-end bg-gray-900 text-white">
      <div ref={chainDropdownRef} className="relative">
        <button
          className="h-full min-w-[10rem] p-2 flex gap-2 justify-between align-middle rounded-md hover:bg-gray-800 transition-colors"
          onClick={() => setIsChainDropDownShown(!isChainDropDownShown)}
        >
          <span className="my-auto">
            {state.chainInfo !== null ? state.chainInfo.name : "Select Chain"}
          </span>
          <FontAwesomeIcon className="my-auto" icon={faChevronDown} />
        </button>
        {isChainDropDownShown && (
          <div className="absolute z-10 top-8 w-full mt-3 p-2 bg-blue-200 text-black">
            <ul>
              {chainInfoList.map((info) => (
                <li key={info.id}>
                  <button
                    className="w-full text-start"
                    onClick={async () => {
                      setIsChainDropDownShown(false);
                      if (state.userAddress === null) {
                        connectWallet();
                      }

                      try {
                        await window.ethereum.request({
                          method: "wallet_switchEthereumChain",
                          params: [{ chainId: info.id }],
                        });
                      } catch (switchError: any) {
                        console.log("switchError is: ", switchError);
                        console.log("switchError.code: ", switchError.code);
                        if (switchError.code === 4902) {
                          try {
                            await window.ethereum.request({
                              method: "wallet_addEthereumChain",
                              params: [
                                {
                                  chainId: info.id,
                                  chainName: info.name,
                                  rpcUrls: [info.rpcUrl],
                                  nativeCurrency: {
                                    name: info.nativeCurrency.name,
                                    symbol: info.nativeCurrency.symbol,
                                    decimals: info.nativeCurrency.decimals,
                                  },
                                  blockExplorerUrls: [info.blockExplorerUrl],
                                },
                              ],
                            });
                          } catch (error) {
                            //
                          }
                        }
                      }
                    }}
                  >
                    {info.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div ref={userDropdownRef} className="relative">
        <button
          onClick={async () => {
            // activateBrowserWallet()

            if (state.userAddress === null) {
              await connectWallet();

              window.location.reload();
            } else {
              setIsUserDropDownShown(!isUserDropDownShown);
            }
          }}
          className="py-2 px-4 flex gap-2 bg-slate-600 rounded-full shadow-md hover:bg-slate-500 transition-colors"
        >
          {/* {
            account !== undefined ? (
              <>
                <span className="uppercase">{`${account.slice(
                  0,
                  6
                )}....${account.slice(-4)}`}</span>
                <FontAwesomeIcon className="my-auto" icon={faChevronDown} />
              </>
            ) : (
              "Connect Wallet"
            )
          } */}
          {state.userAddress !== null ? (
            <>
              <span className="uppercase">{`${state.userAddress.slice(
                0,
                6
              )}....${state.userAddress.slice(-4)}`}</span>
              <FontAwesomeIcon className="my-auto" icon={faChevronDown} />
            </>
          ) : (
            "Connect Wallet"
          )}
        </button>
        {isUserDropDownShown && (
          <div
            id="chainDropdownMenu"
            className="absolute top-8 w-full mt-3 bg-blue-200 text-black"
          >
            <button
              className="p-2 flex gap-2"
              onClick={() => {
                disconnectWallet();
                setIsUserDropDownShown(false);

                window.location.reload();
              }}
            >
              <FontAwesomeIcon className="my-auto" icon={faPowerOff} />
              <span className="uppercase">Disconnect</span>
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default HeadComponent;
