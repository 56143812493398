import { ethers } from "ethers";
import * as sapphire from "@oasisprotocol/sapphire-paratime";
import {
  Contract as ZkSyncContract,
  Web3Provider as ZkSyncWeb3Provider,
  Provider as ZkSyncProvider,
} from "zksync-web3";

import FundMeWithNativeToken from "./assets/FundMeWithNativeToken.json";
import FundMeWithNoneNativeToken from "./assets/FundMeWithNoneNativeToken.json";
import ERC20Token from "./assets/MockERC20.json";

import { getIsConnectedFromStorage } from "./storage/app.storage";

export type BlockchainNetworkId =
  | "0x89"
  | "0x5"
  | "0x118"
  | "0x13881"
  | "0x5aff"
  | "0xa869"
  | "0x507";

export type ChainInfo = {
  id: BlockchainNetworkId;
  name: string;
  rpcUrl: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrl: string;
};

export const chainInfoList: ChainInfo[] = [
  // Ethereum
  {
    id: "0x5",
    name: "Ethereum Goerli (Testnet)",
    rpcUrl: "https://goerli.infura.io/v3/",
    nativeCurrency: {
      name: "Goerli ETH",
      symbol: "GoerliETH",
      decimals: 18,
    },
    blockExplorerUrl: "https://goerli.etherscan.io",
  },
  // ZkSync (Testnet)
  {
    id: "0x118",
    name: "ZkSync Alpha (Testnet)",
    rpcUrl: "https://zksync2-testnet.zksync.dev",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    blockExplorerUrl: "https://explorer.zksync.io/",
  },
  // Polygon
  {
    id: "0x89",
    name: "Polygon",
    rpcUrl: "https://polygon-rpc.com/",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrl: "https://polygonscan.com/",
  },
  {
    id: "0x13881",
    name: "Polygon Mumbai (Testnet)",
    rpcUrl: "https://matic-mumbai.chainstacklabs.com",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
  },
  // Avalanche
  {
    id: "0xa869",
    name: "Avalanche (Testnet)",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    blockExplorerUrl: "https://testnet.snowtrace.io",
  },
  // Moonbeam
  {
    id: "0x507",
    name: "Moonbase Alpha (Testnet)",
    rpcUrl: "https://rpc.api.moonbase.moonbeam.network",
    nativeCurrency: {
      name: "DEV",
      symbol: "DEV",
      decimals: 18,
    },
    blockExplorerUrl: "https://moonbase.moonscan.io",
  },
  // Oasis Sapphire
  {
    id: "0x5aff",
    name: "Sapphire (Testnet)",
    rpcUrl: "https://testnet.sapphire.oasis.dev",
    nativeCurrency: {
      name: "Sapphire ROSE",
      symbol: "sROSE",
      decimals: 18,
    },
    blockExplorerUrl: "https://testnet.explorer.sapphire.oasis.dev/",
  },
];

type CoinNative = {
  name: string;
  symbol: string;
  decimals: number;
  depositContractAddress: string;
  depositContract: ethers.Contract | null;
};

type CoinNonNative = {
  name: string;
  symbol: string;
  decimals: number;
  coinAddress: string;
  coinContract: ethers.Contract | null;
  depositContractAddress: string;
  depositContract: ethers.Contract | null;
};

export type Coin = CoinNative | CoinNonNative;
export const isCoinNoneNative = (input: any): input is CoinNonNative =>
  input.coinAddress !== undefined;

const EthereumGoerliCoinList: Coin[] = [
  {
    name: "GoerliETH",
    symbol: "ETH",
    decimals: 18,
    // depositContractAddress: "0x536D648d68daA791272551627DdE74E8248A9235",
    // depositContractAddress: "0x118d673f1128d08BB4c5f226b312Abe590c114dB",
    depositContractAddress: "0x9a01DE4072018132aF58bA92e9e70e5eF47b6778",
    depositContract: null,
  },
  {
    name: "WrappedBTC",
    symbol: "WBTC",
    decimals: 8,
    coinAddress: "0xda4a47edf8ab3c5eeeb537a97c5b66ea42f49cda",
    coinContract: null,
    // depositContractAddress: "0xAA07d12791A17336186ecF6fd012B4170Eda7D30",
    // depositContractAddress: "0xeF519Ec80066E5d744E5DDa1f2Dd77134132BaE4",
    depositContractAddress: "0xe8155A780a47a064185AEEdd200E66B2c1770740",
    depositContract: null,
  },
  {
    name: "WrappedETH",
    symbol: "ETH",
    decimals: 18,
    coinAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    coinContract: null,
    // depositContractAddress: "0xD9e5210d52d4C81d97DD5072246FD1d2ee34a2e8",
    // depositContractAddress: "0x00e46e8200d00D1C24Ca1aDF5AC96e75428a93c7",
    depositContractAddress: "0xDc4Ea07590dD2d341a990fbAf1E482bd8C76AFf2",
    depositContract: null,
  },
  {
    name: "DAI",
    symbol: "DAI",
    decimals: 18,
    coinAddress: "0xdc31ee1784292379fbb2964b3b9c4124d8f89c60",
    coinContract: null,
    // depositContractAddress: "0xF340663a6EE9b3861BDebd7628d959eE0070F9FF",
    // depositContractAddress: "0x4ABca3486899d17Ad72ED191321A5b563E0D0a67",
    depositContractAddress: "0xF0e5df0Df5946e9CF427DA62D2ca11E624D02eE1",
    depositContract: null,
  },
  {
    name: "USDT",
    symbol: "USDT",
    decimals: 6,
    coinAddress: "0xe802376580c10fe23f027e1e19ed9d54d4c9311e",
    coinContract: null,
    // depositContractAddress: "0xD6BC6889AB78807057eA575fe1fa8F751B2339F4",
    // depositContractAddress: "0x0d44A871ddB122A439aA8D679C0c2B62F2d58539",
    depositContractAddress: "0x56E57c37B42c5eEB8480900CD7470B57Afc1953b",
    depositContract: null,
  },
  {
    name: "USDC",
    symbol: "USDC",
    decimals: 8,
    coinAddress: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
    coinContract: null,
    // depositContractAddress: "0x68Dc2C880a4792BFC67fa9bee7F135C64A21BEc7",
    // depositContractAddress: "0x965556c8C6E0Ecb0a66329A71250d175D69B00F5",
    depositContractAddress: "0x04B21E4eC063e18354b9a0a0A28161356297CE2f",
    depositContract: null,
  },
  {
    name: "BUSD",
    symbol: "BUSD",
    decimals: 18,
    coinAddress: "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7",
    coinContract: null,
    // depositContractAddress: "0x4e4911B9664628B224Eea361484bDe574e818959",
    // depositContractAddress: "0x78112ea6d37545376e2790EDe6c92fbE510526fF",
    depositContractAddress: "0x8d52c8cEF5C031AAe72110202B1d2cd9B72DF100",
    depositContract: null,
  },
];

const ZkSyncAlphaCoinList: Coin[] = [
  {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    depositContractAddress: "0x1Ed00Dc5b66D85aB5dee1eDfEAEA37235B482920",
    depositContract: null,
  },
  {
    name: "WBTC",
    symbol: "WBTC",
    decimals: 8,
    coinAddress: "0xfa9774996e6A39Dc483035f3638d0FD834eC7261",
    coinContract: null,
    depositContractAddress: "0xedaeC1Bb1c28B8b0c2ed4BA518dEa71DF699ea7",
    depositContract: null,
  },
  {
    name: "DAI",
    symbol: "DAI",
    decimals: 18,
    coinAddress: "0x2E4805d59193E173C9C8125B4Fc8F7f9c7a3a3eD",
    coinContract: null,
    depositContractAddress: "0x940BcCDb89D1Fd32fF0fBDc82abF86b3F35eE27d",
    depositContract: null,
  },
  {
    name: "USDC",
    symbol: "USDC",
    decimals: 8,
    coinAddress: "0x852a4599217E76aA725F0AdA8BF832a1F57a8A91",
    coinContract: null,
    depositContractAddress: "0xe0b50dB1722Fb4d2801FB1dFBb792A461D89929e",
    depositContract: null,
  },
];

const PolygonCoinList: Coin[] = [
  {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    depositContractAddress: "0xC5670f357f6cB9fcEB8Cb8EBdA36599061B0B8D4",
    depositContract: null,
  },
];

const PolygonMumbaiCoinList: Coin[] = [
  {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    // depositContractAddress: "0x536D648d68daA791272551627DdE74E8248A9235",
    // depositContractAddress: "0x457c974ECc9a4b2C27986EDEC990e9f925711B17",
    depositContractAddress: "0x4e4911B9664628B224Eea361484bDe574e818959",
    depositContract: null,
  },
  {
    name: "WrappedETH",
    symbol: "WETH",
    decimals: 18,
    coinAddress: "0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa",
    coinContract: null,
    // depositContractAddress: "0x715e2C0e663e628be9fB3F1a7a5f97EEc9688A2A",
    // depositContractAddress: "0xF340663a6EE9b3861BDebd7628d959eE0070F9FF",
    depositContractAddress: "0xe6aAff71DBDF15fA51E3b2E52D1a9f3357133d46",
    depositContract: null,
  },
];

const AvalancheFujiCoinList: Coin[] = [
  {
    name: "AVAX",
    symbol: "AVAX",
    decimals: 18,
    // depositContractAddress: "0x4E6Fbc38091bF8eB1F69e210b81A888aA0e6bc09",
    depositContractAddress: "0x62905B13c9Dd41908C18622804fF78946C69F65F",
    depositContract: null,
  },
];

const MoonbaseAlphaCoinList: Coin[] = [
  {
    name: "DEV",
    symbol: "DEV",
    decimals: 18,
    depositContractAddress: "0x77111E4e1d0c4Be30Ca41E4430c46BBAC4176EFe",
    depositContract: null,
  },
];

const SapphireTestnetCoinList: Coin[] = [
  {
    name: "Sapphire ROSE",
    symbol: "sROSE",
    decimals: 18,
    // depositContractAddress: "0x4E6Fbc38091bF8eB1F69e210b81A888aA0e6bc09",
    // depositContractAddress: "0xe6aAff71DBDF15fA51E3b2E52D1a9f3357133d46",
    depositContractAddress: "0x457c974ECc9a4b2C27986EDEC990e9f925711B17",
    depositContract: null,
  },
];

export const setup = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const chainInfoListMap = new Map<BlockchainNetworkId, Coin[]>();

  const newEthereumGoerliCoinList: Coin[] = [];
  EthereumGoerliCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ethers.Contract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        provider.getSigner()
      );
      newEthereumGoerliCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ethers.Contract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        provider.getSigner()
      );
      const depositContract = new ethers.Contract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        provider.getSigner()
      );
      newEthereumGoerliCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0x5", newEthereumGoerliCoinList);

  const newZkSyncAlphaCoinList: Coin[] = [];
  ZkSyncAlphaCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ZkSyncContract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        new ZkSyncWeb3Provider(window.ethereum).getSigner()
      );
      newZkSyncAlphaCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ZkSyncContract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        new ZkSyncWeb3Provider(window.ethereum).getSigner()
      );
      const depositContract = new ZkSyncContract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        new ZkSyncWeb3Provider(window.ethereum).getSigner()
      );
      newZkSyncAlphaCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0x118", newZkSyncAlphaCoinList);

  const newPolygonCoinList: Coin[] = [];
  PolygonCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ethers.Contract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        provider.getSigner()
      );
      newPolygonCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ethers.Contract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        provider.getSigner()
      );
      const depositContract = new ethers.Contract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        provider.getSigner()
      );
      newPolygonCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0x89", newPolygonCoinList);

  const newPolygonMumbaiCoinList: Coin[] = [];
  PolygonMumbaiCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ethers.Contract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        provider.getSigner()
      );
      newPolygonMumbaiCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ethers.Contract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        provider.getSigner()
      );
      const depositContract = new ethers.Contract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        provider.getSigner()
      );
      newPolygonMumbaiCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0x13881", newPolygonMumbaiCoinList);

  const newAvalancheFujiCoinList: Coin[] = [];
  AvalancheFujiCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ethers.Contract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        provider.getSigner()
      );
      newAvalancheFujiCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ethers.Contract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        provider.getSigner()
      );
      const depositContract = new ethers.Contract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        provider.getSigner()
      );
      newAvalancheFujiCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0xa869", newAvalancheFujiCoinList);

  const newMoonbaseAlphaCoinList: Coin[] = [];
  MoonbaseAlphaCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ethers.Contract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        provider.getSigner()
      );
      newMoonbaseAlphaCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ethers.Contract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        provider.getSigner()
      );
      const depositContract = new ethers.Contract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        provider.getSigner()
      );
      newMoonbaseAlphaCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0x507", newMoonbaseAlphaCoinList);

  const newSapphireTestnetCoinList: Coin[] = [];
  SapphireTestnetCoinList.forEach((coin, index) => {
    if (index === 0) {
      const depositContract = new ethers.Contract(
        coin.depositContractAddress,
        FundMeWithNativeToken.abi,
        sapphire.wrap(provider.getSigner())
      );
      newSapphireTestnetCoinList.push({
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        depositContractAddress: coin.depositContractAddress,
        depositContract: depositContract,
      });
    } else {
      const innerCoin = coin as CoinNonNative;
      const coinContract = new ethers.Contract(
        innerCoin.coinAddress,
        ERC20Token.abi,
        sapphire.wrap(provider.getSigner())
      );
      const depositContract = new ethers.Contract(
        innerCoin.depositContractAddress,
        FundMeWithNoneNativeToken.abi,
        sapphire.wrap(provider.getSigner())
      );
      newSapphireTestnetCoinList.push({
        name: innerCoin.name,
        symbol: innerCoin.symbol,
        decimals: innerCoin.decimals,
        coinAddress: innerCoin.coinAddress,
        coinContract: coinContract,
        depositContractAddress: innerCoin.depositContractAddress,
        depositContract: depositContract,
      });
    }
  });
  chainInfoListMap.set("0x5aff", newSapphireTestnetCoinList);

  return {
    provider,
    chainInfoListMap,
  };
};

export const isConnectedToMetamask = () => {
  return getIsConnectedFromStorage();
};

export const getChainInfoFromNetworkId = (networkId: string | null) => {
  if (networkId === null) {
    return null;
  }

  let selectedChainInfo: ChainInfo | null = null;

  chainInfoList.forEach((chainInfo) => {
    if (chainInfo.id === networkId) {
      selectedChainInfo = chainInfo;
    }
  });

  return selectedChainInfo;
};

export const getSelectedChainCoins = (
  chainNetworkId: string | null,
  chainInfoListMap: Map<BlockchainNetworkId, Coin[]>
) => {
  if (chainNetworkId === null) {
    return null;
  }
  const coinList =
    chainInfoListMap.get(chainNetworkId as BlockchainNetworkId) ?? null;

  return coinList;
};

export const getCoinDepositContract = async (
  userWalletAddress: string | null,
  web3Provider: ethers.providers.Web3Provider | ZkSyncWeb3Provider | null,
  coin: Coin
) => {
  if (web3Provider === null || userWalletAddress === null) {
    return null;
  }

  if (isCoinNoneNative(coin)) {
    if (coin.coinContract === null) {
      return null;
    }
    const balance = await coin.coinContract.balanceOf(userWalletAddress);

    return balance as ethers.BigNumber;
  } else {
    const balance = await web3Provider.getBalance(userWalletAddress);

    return balance;
  }
};

export const getIsAllownceApproved = async (
  moneyToTransfer: ethers.BigNumber,
  userWalletAddress: string | null,
  coin: Coin,
  web3Provider: ethers.providers.Web3Provider | ZkSyncWeb3Provider | null
) => {
  let areFundsEnough = false;
  let isTransferAllowed = false;
  if (web3Provider === null || userWalletAddress === null) {
    return {
      areFundsEnough,
      isTransferAllowed,
    };
  }

  if (isCoinNoneNative(coin)) {
    if (coin.coinContract === null) {
      return {
        areFundsEnough,
        isTransferAllowed,
      };
    }

    const balance = (await coin.coinContract.balanceOf(
      userWalletAddress
    )) as ethers.BigNumber;
    areFundsEnough = !balance.sub(moneyToTransfer).isNegative();

    const allownce = (await coin.coinContract.allowance(
      userWalletAddress,
      coin.depositContractAddress
    )) as ethers.BigNumber;

    isTransferAllowed =
      !allownce.sub(moneyToTransfer).isNegative() && !allownce.isZero();

    return {
      areFundsEnough,
      isTransferAllowed,
    };
  } else {
    const balance = await web3Provider.getBalance(userWalletAddress);
    areFundsEnough = !balance.sub(moneyToTransfer).isNegative();

    isTransferAllowed =
      !balance.sub(moneyToTransfer).isNegative() && !moneyToTransfer.isZero();

    return {
      areFundsEnough,
      isTransferAllowed,
    };
  }
};

export const getAmountFundedByAddress = async (
  coin: Coin,
  userWalletAddress: string | null
) => {
  if (coin.depositContract === null) {
    return null;
  }

  const balance = (await coin.depositContract.getAddressToAmountFunded(
    userWalletAddress
  )) as ethers.BigNumber;

  return balance;
};

export const approve = async (
  coin: Coin,
  moneyToTransfer: ethers.BigNumber
) => {
  if (coin.depositContract === null) {
    return null;
  }

  if (isCoinNoneNative(coin) && coin.coinContract !== null) {
    await coin.coinContract.approve(
      coin.depositContractAddress,
      moneyToTransfer
    );
  }
  // await coin.depositContract.fund({ value: moneyToTransfer });

  return true;
};

export const fund = async (coin: Coin, moneyToTransfer: ethers.BigNumber) => {
  if (coin.depositContract === null) {
    return null;
  }

  if (isCoinNoneNative(coin) && coin.coinContract !== null) {
    await coin.depositContract.fund(moneyToTransfer);
  } else {
    await coin.depositContract.fund({ value: moneyToTransfer });
  }

  return true;
};

export const withdraw = async (coin: Coin) => {
  if (coin.depositContract === null) {
    return null;
  }

  await coin.depositContract.withdraw();

  return true;
};

export const getIsContractOwner = async (
  coin: Coin,
  web3Provider: ethers.providers.Web3Provider | ZkSyncWeb3Provider | null
) => {
  if (coin.depositContract === null || web3Provider === null) {
    return null;
  }

  const owner = (await coin.depositContract.getOwner()) as string;
  const user = await web3Provider.getSigner().getAddress();

  return owner === user;
};

export const getStoredBalance = async (coin: Coin) => {
  if (coin.depositContract === null) {
    return null;
  }

  const balance =
    (await coin.depositContract.getStoredBalance()) as ethers.BigNumber;

  return balance;
};
