import { AppActionTypes, AppStoreState } from "./app.types";

export const appReducer = (
  state: AppStoreState,
  actionWithPayload: {
    action: AppActionTypes;
    payload: AppStoreState;
  }
): AppStoreState => {
  const newState: AppStoreState = {
    userAddress: state.userAddress,
    chainInfo: state.chainInfo,
    web3Provider: state.web3Provider,
    chainInfoListMap: state.chainInfoListMap,
  };

  switch (actionWithPayload.action) {
    case AppActionTypes.SETUP:
      newState.userAddress = actionWithPayload.payload.userAddress;
      newState.chainInfo = actionWithPayload.payload.chainInfo;
      newState.web3Provider = actionWithPayload.payload.web3Provider;
      newState.chainInfoListMap = actionWithPayload.payload.chainInfoListMap

      return newState;
    case AppActionTypes.SET_ADDRESS_AND_NETWORK:
      newState.userAddress = actionWithPayload.payload.userAddress;
      newState.chainInfo = actionWithPayload.payload.chainInfo;

      return newState;
    case AppActionTypes.SET_NETWORK:
      newState.chainInfo = actionWithPayload.payload.chainInfo;

      return newState;
    case AppActionTypes.SET_SELECTED_COIN:

      return newState;
    default:
      return newState;
  }
};
