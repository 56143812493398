import { useContext } from "react"

import { AppContext } from "./app.context"

export const useAppContext = () => {
    const context = useContext(AppContext)

    if (!context) {
        throw Error('useAppContext must be used inside a AppContextProvider')
    }

    return context
}